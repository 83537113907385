import React, { useState } from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import BildHeader from "../../images/services/gewerbe-industrie/header.jpg"
import BildObenLinks from "../../images/services/gewerbe-industrie/obenlinks.jpg"
import BildObenRechts from "../../images/services/gewerbe-industrie/obenrechts.jpg"
import SubNavigation from "../../components/SubNavServices"
import Termin from "../../components/Termin"

const GewerbeUndIndustrieberatung: React.FC = () => {
  const siteTitle = "Gewerbe- und Industrieberatung – Dienstleistungen"
  const [isSubMenuOpen, setSubMenuOpen] = useState(false)

  return (
    <Layout title={siteTitle}>
      <SEO title={siteTitle} />
      <section className="wrapper">
        <div className="headerImage">
          <img src={BildHeader} />
        </div>
      </section>
      <Termin />
      <SubNavigation
        isSubMenuOpen={isSubMenuOpen}
        setSubMenuOpen={setSubMenuOpen}
        activeMenuText="Gewerbe- und Industrieberatung"
      />
      <section className="wrapper greyColor content">
        <h1>Gewerbe- und Industrie</h1>
        <h2 className="contentSubtitle">Beratung von SCHWARZ Optik</h2>
        <div className="contentColumns">
          <div className="contentColumn">
            <div className="imageCollection">
              <div className="imageColumn">
                <a
                  href="https://www.uvex-safety.com/de/produkte/schutzbrillen/"
                  target="_blank"
                  style={{ textDecoration: "none" }}
                >
                  <img src={BildObenLinks} />
                  <p className="imageCaption">
                    UVEX Schutzbrillen –<br />
                    Säurebeständig
                  </p>
                </a>
              </div>
              <div className="imageColumn">
                <a
                  href="https://www.uvex-safety.com/de/produkte/schutzbrillen/"
                  target="_blank"
                  style={{ textDecoration: "none" }}
                >
                  <img src={BildObenRechts} />
                  <p className="imageCaption">
                    UVEX Schutzbrillen –<br />
                    Kratzbeständig
                  </p>
                </a>
              </div>
            </div>
          </div>
          <div className="contentColumn">
            <h2>Schutzbrillen</h2>
            <p>
              An vielen Arbeitsplätzen werden die Augen besonders beansprucht
              oder sind gar durch handwerkliche oder maschinelle Arbeiten
              gefährdet. SCHWARZ Optik analysiert Arbeitsplätze und erarbeitet
              Empfehlungen für Sicht- und Schutzbrillen an gewerblichen,
              industriellen oder Büro-Arbeitsplätzen.
            </p>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default GewerbeUndIndustrieberatung
